@font-face {
    font-family: 'Inconsolata';
    src: url('../public/Inconsolata-Regular.ttf');
}

:root {
    --bg-color: #0c0f2b;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: "Inconsolata", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    box-sizing: border-box;
    background: var(--bg-color);
}

.App {
    color: #fff;
    height: 100vh;
    padding: 0 1rem;
}

.code {
    border-radius: 6px;
}


.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.heading {
    text-align: center;
}

.example {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.code {
    max-width: 34rem;
    width: 90%;
    margin: 0 auto 1rem;
}

.next {
    color: burlywood;
    font-size: 1.15rem;
    cursor: pointer;
}

.nav {
    margin-top: auto;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.text-pro {
    color: greenyellow;
}

.text-con {
    color: #fa7b7b;
}

.demo {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: burlywood;
    color: #000000;
    padding: .5rem 1rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--bg-color);
}

.flex-content {
    border: 1px solid #fff;
    padding: 2rem;
    background-color: #333;
    text-align: center;
}

.grid-container {
    display: grid;
    place-items: center;
    height: 100vh;
    background-color: var(--bg-color);
}

.grid-content {
    border: 1px solid #fff;
    padding: 2rem;
    background-color: #333;
    text-align: center;
}

.table-container {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 100vh;
    width: 100vw;
    background-color: var(--bg-color);
}

.table-content {
    border: 1px solid #fff;
    padding: 2rem;
    background-color: #333;
    text-align: center;
    width: max-content;
    margin: auto;
}

.transform-container {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: var(--bg-color);
}

.transform-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #fff;
    padding: 2rem;
    background-color: #333;
    text-align: center;
}

.transform_margin-container {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: var(--bg-color);
}

.transform_margin-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 80%;
    height: fit-content;
    border: 1px solid #fff;
    padding: 2rem;
    background-color: #333;
    text-align: center;
}

.line-height-container {
    height: 100vh;
    line-height: 100vh;
    text-align: center;
    background-color: var(--bg-color);
}

.footer {
    margin-top: 2rem;
    text-align: center;
    margin-bottom: 1rem;
    font-size: .8rem;
}


.landscape_container {
    display: none;
}


@media screen and (min-width: 320px) and (max-width: 980px) and (orientation: landscape) {

    .landscape_container {
        visibility: visible;
        display: flex;
        height: 100%;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .wrapper {
       display: none;
    }

}
